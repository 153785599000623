import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { SectionWrapper } from '../LightenTheLoad/styles'

import {
  SectionPadding,
  TitleContainer,
  Title,
  CardTitle,
  CardDescription,
  CardPrice,
  CardPriceContainer,
  HeroContent,
  GridContainer,
  Card,
  CardContent,
  ShopNow,
  SectionTitle,
  StarsContainer,
  DesktopImage,
  MobileImage,
  SectionContainer,
  DisplayOnMobile,
  DisplayOnDesktop,
} from '../styles'

const MostGifted = ({ page }) => {
  const {
    giftedFrame,
    giftedFrameMobile,
    giftedFrame2,
    giftedFrame2Mobile,
    giftedCal,
    giftedCalMobile,
    background,
    backgroundMobile,
    stars,
    bundleOne,
    bundleTwo,
    bundleThree,
    contentful10FramePrice,
    contentfulFrame2Price,
    contentfulCalPrice,
  } = useStaticQuery(graphql`
    query mostGiftedQuery {
      giftedFrame: file(relativePath: { eq: "giftGuide/gifted-1.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      giftedFrameMobile: file(relativePath: { eq: "giftGuide/gifted-1-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      giftedFrame2: file(relativePath: { eq: "giftGuide/gifted-2.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      giftedFrame2Mobile: file(relativePath: { eq: "giftGuide/gifted-2-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      giftedCal: file(relativePath: { eq: "giftGuide/gifted-3.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      giftedCalMobile: file(relativePath: { eq: "giftGuide/gifted-3-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      background: file(relativePath: { eq: "giftGuide/lighten-bg.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      backgroundMobile: file(relativePath: { eq: "giftGuide/background-mobile-bg.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      stars: file(relativePath: { eq: "giftGuide/stars.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      bundleOne: file(relativePath: { eq: "giftGuide/bundle-1.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      bundleTwo: file(relativePath: { eq: "giftGuide/bundle-2.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      bundleThree: file(relativePath: { eq: "giftGuide/bundle-3.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      contentful10FramePrice: allContentful10InFramePriceStrikethrough {
        edges {
          node {
            en_original_price
            en_discounted_price
          }
        }
      }
      contentfulFrame2Price: allContentfulFrame2AndSnapFrameStrikethrough {
        edges {
          node {
            wood_walnut_original_price
            wood_walnut_discounted_price
          }
        }
      }
      contentfulCalPrice: allContentfulCalendarStrikeThoughPrice {
        edges {
          node {
            cal_15_with_plus_original_price
            cal_15_with_plus_discounted_price
          }
        }
      }
    }
  `)
  const { en_original_price, en_discounted_price } = contentful10FramePrice.edges[0]?.node || {}
  const { wood_walnut_original_price, wood_walnut_discounted_price } =
    contentfulFrame2Price.edges[0]?.node || {}
  const { cal_15_with_plus_original_price, cal_15_with_plus_discounted_price } =
    contentfulCalPrice.edges[0]?.node || {}

  const formatPrice = (price) => (price && price !== 'NONE' ? `$${price}` : '')
  const products = {
    gifted: {
      title: 'Most Gifted',
      subHeading: '8,300+ five-star reviews.',
      showStars: true,
      productDetails: [
        {
          title: '10" Black Frame',
          description: 'The best. Sweetest. Highest rated. Basically the "-est" of all gifts.',
          link: 'the-skylight-frame',
          image: giftedFrame,
          mobileImage: giftedFrameMobile,
          price: formatPrice(en_original_price),
          discountedPrice: formatPrice(en_discounted_price),
        },
        {
          title: 'Frame 2',
          description: 'The only frame with swappable style.',
          link: 'skylight-frame-2-wood-walnut',
          image: giftedFrame2,
          mobileImage: giftedFrame2Mobile,
          price: formatPrice(wood_walnut_original_price),
          discountedPrice: formatPrice(wood_walnut_discounted_price),
        },
        {
          title: '15" Calendar with Plus',
          description: 'The gift that actually keeps on giving.',
          link: 'skylight-calendar',
          image: giftedCal,
          mobileImage: giftedCalMobile,
          price: formatPrice(cal_15_with_plus_original_price),
          discountedPrice: formatPrice(cal_15_with_plus_discounted_price),
        },
      ],
    },
    bundle: {
      title: 'Bundle Up',
      subHeading: 'The more, the merrier.',
      showStars: false,
      productDetails: [
        {
          title: '10" Frame 2-Pack',
          price: '$XXX',
          image: bundleOne,
        },
        {
          title: '15" Calendar + 10" Black Frame with Plus',
          price: '$XXX',
          image: bundleTwo,
        },
        {
          title: 'Frame 2 + Snap Frame(s)',
          price: '$XXX',
          image: bundleThree,
        },
      ],
    },
  }
  const { title, subHeading, showStars, productDetails } = products[page]
  return (
    <>
      <SectionWrapper>
        {showStars && (
          <>
            <DesktopImage image={background.childImageSharp.gatsbyImageData} />
            <MobileImage image={backgroundMobile.childImageSharp.gatsbyImageData} />
          </>
        )}
        <SectionContainer>
          <div className="content">
            <HeroContent noTopPadding>
              <SectionTitle space={!showStars}>{title}</SectionTitle>
              <Title space={!showStars}>{subHeading}</Title>
              {showStars && (
                <StarsContainer>
                  <GatsbyImage image={stars.childImageSharp.gatsbyImageData} alt="5 Star Reviews" />
                </StarsContainer>
              )}
              <SectionPadding>
                <GridContainer>
                  {productDetails.map((product, index) => (
                    <Card key={index} hide={!showStars}>
                      <DisplayOnDesktop>
                        <GatsbyImage
                          image={product.image.childImageSharp.gatsbyImageData}
                          alt={product.title}
                        />
                      </DisplayOnDesktop>
                      <DisplayOnMobile>
                        <GatsbyImage
                          image={product.mobileImage.childImageSharp.gatsbyImageData}
                          alt={product.title}
                        />
                      </DisplayOnMobile>
                      <CardContent space={!showStars}>
                        <CardTitle>{product.title}</CardTitle>
                        {product?.description && (
                          <CardDescription>{product.description}</CardDescription>
                        )}
                        <CardPriceContainer>
                          {product.price && (
                            <CardPrice strikethrough={product.discountedPrice}>
                              {product.price}
                            </CardPrice>
                          )}
                          {product.discountedPrice && (
                            <CardPrice red>{product.discountedPrice}</CardPrice>
                          )}
                        </CardPriceContainer>
                        <ShopNow to={`/products/${product.link}`}>Shop Now →</ShopNow>
                      </CardContent>
                    </Card>
                  ))}
                </GridContainer>
              </SectionPadding>
            </HeroContent>
          </div>
        </SectionContainer>
      </SectionWrapper>
    </>
  )
}

export default MostGifted

MostGifted.propTypes = {
  page: PropTypes.string,
}
