import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { SectionWrapper } from '../LightenTheLoad/styles'
import GiftButtons from '../GiftButtons'
import {
  Logo,
  PageTitle,
  SectionPadding,
  TitleContainer,
  Title,
  DesktopImage,
  MobileImage,
  HeroContent,
  SectionContainer,
} from '../styles'

const GiftHero = () => {
  const { heroDesktop, heroMobile } = useStaticQuery(graphql`
    query giftHeroQuery {
      heroDesktop: file(relativePath: { eq: "giftGuide/hero-desktop.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      heroMobile: file(relativePath: { eq: "giftGuide/hero-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
    }
  `)

  return (
    <>
      <SectionWrapper>
        <DesktopImage image={heroDesktop.childImageSharp.gatsbyImageData} />
        <MobileImage image={heroMobile.childImageSharp.gatsbyImageData} />
        <SectionPadding>
          <SectionContainer>
            <div className="content">
              <HeroContent>
                <Logo>Skylight</Logo>
                <PageTitle>Gift Essentials</PageTitle>
                <TitleContainer>
                  <Title>
                    The gifts everyone wants — <br />
                    before, during and after the holidays.
                  </Title>
                </TitleContainer>
              </HeroContent>
              <GiftButtons isMobile={false} />
            </div>
          </SectionContainer>
        </SectionPadding>
      </SectionWrapper>
      <GiftButtons isMobile />
    </>
  )
}

export default GiftHero
