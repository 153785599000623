import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { withTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import StoreContext from 'src/context/StoreContext'

import SEO from '../components/seo'
import LightenTheLoad from '../components/GiftGuide/LightenTheLoad'
import GiftHero from '../components/GiftGuide/GiftHero'
import MostGifted from '../components/GiftGuide/MostGifted'
import Time from '../components/GiftGuide/Time'

import { HideOverflow } from '../utils/styles/global-styles'
import Analytics from '../utils/Analytics'

class GiftGuide extends React.Component {
  componentDidMount() {
    Analytics.track('Viewed Gift Guide Landing Page')
  }

  render() {
    const { t, data } = this.props
    const { frameCalImage } = data

    return (
      <>
        <SEO
          title={t('Gift Guide | Skylight Frame')}
          description={t(
            'The perfect gift at the perfect price. Skylight changes the way you connect with loved ones. Skylight digital picture frames are the best way to share photos. Skylight Smart Touchscreen Calendars are Family Organizer that serve as the hub of the home for all your events and chores.'
          )}
          imagePath={frameCalImage.childImageSharp.gatsbyImageData.images.fallback.src}
          path="/gift-guide/"
        />
        <Helmet>
          <meta charset="UTF-8" />
          <link rel="canonical" href="https://www.skylightframe.com/gift-guide" />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
          />
        </Helmet>
        <HideOverflow>
          <GiftHero />
          <MostGifted page="gifted" />
          <Time />
          <LightenTheLoad />
        </HideOverflow>
      </>
    )
  }
}

GiftGuide.contextType = StoreContext

GiftGuide.propTypes = {
  t: PropTypes.func,
  data: PropTypes.object.isRequired,
}

export default withTranslation()(GiftGuide)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "frame"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    frameCalImage: file(relativePath: { eq: "homepage/baby-dog-in-wood-frame-mobile.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
  }
`
