import React from 'react'
import Slider from 'react-slick'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  CarouselContainer,
  Header,
  Text,
  ImageContainer,
  Grid,
  Title,
  Description,
  TextContainer,
} from './styles'

const GiftCarousel = ({ testimonials, frame }) => {
  const { deviceLinking, sidekick, giftMode, touchScreen, easySetup } = useStaticQuery(graphql`
    query GiftCarouselQuery {
      deviceLinking: file(relativePath: { eq: "giftGuide/device-linking.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      sidekick: file(relativePath: { eq: "giftGuide/sidekick.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      giftMode: file(relativePath: { eq: "giftGuide/gift-mode.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      touchScreen: file(relativePath: { eq: "giftGuide/touch-screen.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      easySetup: file(relativePath: { eq: "giftGuide/easy-setup.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
    }
  `)
  const sliderDetails = [
    {
      title: 'Device Linking',
      description: 'Less juggle, more jingle. Sync across Frames and Calendars.',
      image: deviceLinking,
    },
    {
      title: 'Sidekick',
      description: `Ooh, baby, it's calm inside — with an AI assistant.`,
      image: sidekick,
    },
  ]
  const frameSliderDetails = [
    {
      title: 'Gift Mode',
      description: 'Load it up with photos - without unboxing.',
      image: giftMode,
    },
    {
      title: 'Touch Screen',
      description: `Swipe, browse, like, zoom, pause.`,
      image: touchScreen,
    },
    {
      title: 'Easy Setup',
      description: `Plug in and connect to WIFi in one minute or less.`,
      image: easySetup,
    },
  ]
  const testimonialDetails = [
    {
      title: 'The Best of the Best',
      description: `“I absolutely LOVE this calendar. It's making life with 3 very busy children and 2 very busy adults much more organized!!!”`,
    },
    {
      title: 'The Best of the Best',
      description: `“Love this Calendar! I don't know how I managed without it! Makes life so much easier for our family.”`,
    },
    {
      title: 'The Best of the Best',
      description: `“Skylight Frame was the best gift my grandmother has ever received.”`,
    },
    {
      title: 'The Best of the Best',
      description: `“I received a Skylight Frame last year for Christmas. I absolutely love it, love it so much that I bought 3 to give as gifts this year!!”`,
    },
    {
      title: 'The Best of the Best',
      description: `“Not only is Calendar Max a beautiful and modern design, but it also makes family calendar management SO easy.”`,
    },
    {
      title: 'The Best of the Best',
      description: `“I love my Skylight Frame and also bought one for my mother in law. It's easy to use and holds so many photos.”`,
    },
  ]
  const sliderContent = testimonials
    ? testimonialDetails
    : frame
    ? frameSliderDetails
    : sliderDetails
  return (
    <CarouselContainer mobileNoPadding={testimonials}>
      <Slider autoplay infinite speed={100} slidesToShow={1} slidesToScroll={1} dots arrows={false}>
        {sliderContent.map((detail, index) => (
          <Grid full={testimonials}>
            {detail.image ? (
              <>
                <TextContainer>
                  <Header>{detail.title}</Header>
                  <Text>{detail.description}</Text>
                </TextContainer>
                <ImageContainer key={index}>
                  <GatsbyImage
                    image={detail.image.childImageSharp.gatsbyImageData}
                    alt="Greeting Card"
                  />
                </ImageContainer>
              </>
            ) : (
              <TextContainer>
                <Title>{detail.title}</Title>
                <Description>{detail.description}</Description>
              </TextContainer>
            )}
          </Grid>
        ))}
      </Slider>
    </CarouselContainer>
  )
}

GiftCarousel.propTypes = {
  testimonials: PropTypes.string,
  frame: PropTypes.bool,
}

export default GiftCarousel
