import React from 'react'
import PropTypes from 'prop-types'
import { GiftButton, StyledLink, GiftButtonContainer } from '../styles'

const GiftButtons = ({ isMobile }) => (
  <GiftButtonContainer mobile={isMobile}>
    <StyledLink to="#frame-essentials">
      <GiftButton>Shop Frames</GiftButton>
    </StyledLink>
    <StyledLink to="#calendar-essentials">
      <GiftButton>Shop Calendars</GiftButton>
    </StyledLink>
  </GiftButtonContainer>
)

export default GiftButtons

GiftButtons.propTypes = {
  isMobile: PropTypes.bool,
}
