import styled, { css } from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export const Logo = styled.p`
  font-size: 34px;
  line-height: 98px;
  letter-spacing: 0.345px;
  font-family: 'P22MackinacProBook';
  margin: 0px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 22px;
    line-height: 48px;
    letter-spacing: 0.218px;
  }
`
export const PageTitle = styled.h1`
  color: white;
  font-family: 'MatterBold';
  font-size: 57.791px;
  line-height: 48px;
  letter-spacing: 0.578px;
  margin: 0px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 44px;
    line-height: 40px;
    letter-spacing: 0.438px;
  }
`
export const TitleContainer = styled.div`
  max-width: ${(props) => (props.max ? '600px' : '100%')};
  padding-bottom: ${(props) => (props.max ? '32px' : '0px')};
  margin: auto;
`
export const SectionTitle = styled.h2`
  color: ${(props) => (props.space ? props.theme.space : 'white')};
  font-family: 'P22MackinacProMedium';
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.4px;
  margin: 56px 0px 0px;
  @media (max-width: ${breakpoints.s}px) {
    margin: 32px 0px 0px;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.24px;
  }
`
export const Title = styled.h2`
  color: ${(props) => (props.space ? props.theme.space : 'white')};
  font-family: 'MatterRegular';
  font-size: 30px;
  line-height: 33px;
  letter-spacing: 0.3px;
  margin: 16px 0px;
  @media (max-width: 1400px) {
    font-size: 24px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.16px;
    margin: 8px 0px;
  }
`
export const StyledLink = styled(Link)`
  text-decoration: none;
`

export const GiftButton = styled.div`
  padding: 8px 20px;
  border-radius: 28px;
  border: 1px solid white;
  display: inline-block;
  color: white;
  font-family: 'MatterRegular';
  font-size: 20px;
  :hover {
    text-decoration: underline;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    text-align: center;
    display: block;
    border-radius: 0px;
    border: none;
    border-top: 1px solid ${(props) => props.theme.space};
    border-bottom: 1px solid ${(props) => props.theme.space};
    background-color: ${(props) => props.theme.cloud};
    color: ${(props) => props.theme.space};
    :hover {
      text-decoration: none;
      color: ${(props) => props.theme.coral};
    }
    &:last-child {
      border-top: none;
    }
  }
`

export const TimeButton = styled(GiftButton)`
  @media (max-width: ${breakpoints.s}px) {
    background-color: transparent;
    border-bottom: none;
    color: white;
    text-decoration: underline;
    padding: 0px;
  }
`
export const SectionPadding = styled.div`
  padding: 32px 0px 64px;
  @media (max-width: ${breakpoints.s}px) {
    padding: 32px 0px 0px;
  }
`
export const SectionContainer = styled.div`
  width: 90%;
  margin: auto;
`
export const GiftButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  display: ${(props) => (props.mobile ? 'none' : 'flex')};
  padding-bottom: 56px;
  padding-top: 32px;
  @media (max-width: ${breakpoints.s}px) {
    gap: 0px;
    flex-direction: column;
    display: ${(props) => (props.mobile ? 'flex' : 'none')};
    padding-bottom: 0px;
    padding-top: 0px;
  }
`
export const DesktopImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`
export const MobileImage = styled(GatsbyImage)`
  display: none;
  @media (max-width: ${breakpoints.s}px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`
export const HeroContent = styled.div`
  @media (max-width: ${breakpoints.s}px) {
    padding: ${(props) => (props.noTopPadding ? '0px 0px 40px' : '40px 0px 40px')};
  }
`
export const LightenLoadContent = styled.div`
  padding-bottom: 48px;
  @media (max-width: ${breakpoints.s}px) {
    padding-bottom: 0px;
  }
`

export const GridContainer = styled.div`
  display: grid;
  max-width: 1200px;
  margin: auto;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  @media (max-width: ${breakpoints.s}px) {
    gap: 20px;
  }
`
export const LightenLoadGridContainer = styled(GridContainer)`
  margin-bottom: 40px;
  @media (max-width: ${breakpoints.xl}px) {
    grid-template-columns: 1fr;
    & > * {
      grid-column: 1;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    gap: 0px;
    margin-bottom: 0px;
    padding-bottom: 40px;
  }
`

export const Card = styled.div`
  background: ${(props) => (props.transparent ? 'transparent' : 'white')};
  overflow: hidden;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: ${(props) =>
    props.hide ? '0px 0px 60px' : props.transparent ? '20px 20px 0px' : '20px 20px 60px'};
  position: relative;
  @media (max-width: ${breakpoints.s}px) {
    padding: 16px 16px 60px;
    flex-direction: ${(props) => (props.transparent ? 'row' : 'column')};
    .lighten {
      margin: 0px;
      .delivery {
        width: 60px;
      }
      .contact {
        width: 54px;
      }
      .truck {
        width: 86px;
      }
    }
  }
`
export const LightenCard = styled(Card)`
  .lighten {
    text-align: center;
    H4,
    p,
    a {
      color: white;
      margin: 0px;
    }
    .delivery {
      width: 114px;
    }
    .contact {
      width: 92px;
    }
    .truck {
      width: 130px;
    }
  }
  @media (max-width: ${breakpoints.xl}px) {
    .lighten {
      display: flex;
      flex-direction: row;
      width: 500px;
      margin: auto;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 16px 0px;
    flex-direction: ${(props) => (props.transparent ? 'row' : 'column')};
    .lighten {
      margin: 0px;
      width: 100%;
      .delivery {
        width: 64px;
      }
      .contact {
        width: 54px;
      }
      .truck {
        width: 70px;
      }
    }
  }
`
export const CardContent = styled.div`
  padding: ${(props) => (props.space ? '20px 20px 0px 20px' : '0px')};
  a {
    margin: 0px;
  }
`

export const TextStyled = css`
  color: ${(props) => props.theme.space};
  font-family: 'MatterRegular';
  margin: 0px;
`

export const CardTitle = styled.h4`
  font-family: 'MatterBold';
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.space};
  margin: 16px 0px 8px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 18px;
  }
`
export const CardDescription = styled.p`
  ${TextStyled}
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.16px;
`
export const CardPrice = styled.p`
  ${TextStyled}
  font-size: 20px;
  line-height: 127%;
  letter-spacing: 0.2px;
  color: ${(props) => (props.red ? '#CB434C' : props.theme.space)};
  text-decoration: ${(props) => (props.strikethrough ? 'line-through' : 'none')};
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
  }
`
export const CardPriceContainer = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 8px;
`
export const ShopNow = styled(Link)`
  color: ${(props) => props.theme.space};
  font-family: 'MatterBold';
  font-size: 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  line-height: 120%;
  letter-spacing: -0.24px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
  }
`
// export const Badge = styled(GatsbyImage)`
//   position: absolute;
//   top: 30px;
//   right: 30px;
//   width: 120px;
//   @media (max-width: ${breakpoints.xl}px) {
//     top: 20px;
//     right: 20px;
//   }
//   @media (max-width: ${breakpoints.s}px) {
//     width: 80px;
//     top: ${(props) => (props.rightOnMobile ? '10px' : '30px')};
//     left: ${(props) => (props.rightOnMobile ? 'unset' : '30px')};
//     right: ${(props) => (props.rightOnMobile ? '30px' : '0px')};
//   }
// `
export const StarsContainer = styled.div`
  width: 170px;
  margin: auto;
  @media (max-width: ${breakpoints.m}px) {
    width: 90px;
  }
`
export const SpanGifContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.cream};
  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 16px;
  }
`
export const GifContentContainer = styled.div`
  flex: 1;
  padding: 0px 25px 0px 25px;
  text-align: left;
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
    padding: 24px 0px 0px;
  }
`
export const ProductSectionTitle = styled.h4`
  color: ${(props) => props.theme.space};
  font-family: 'P22MackinacProBold';
  font-size: 37px;
  line-height: 120%;
  letter-spacing: 0.371px;
  margin: 0px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 24px;
  }
`

export const GiftDetail = styled.p`
  color: ${(props) => props.theme.space};
  font-family: 'MatterRegular';
  font-size: 24px;
  line-height: 127%;
  letter-spacing: 0.247px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 16px;
  }
`

export const GifImageContainer = styled.div`
  flex: 1;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  img:first-child {
    display: block;
  }
  img:last-child {
    display: none;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
    img:first-child {
      display: none;
    }
    img:last-child {
      display: block;
    }
  }
`
export const TimeGifImageContainer = styled(GifImageContainer)`
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
    img:first-child {
      display: none;
    }
    img:last-child {
      display: block;
    }
  }
`

export const ProductLabel = styled.p`
  font-family: 'MatterRegular';
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.333px;
  text-decoration-line: underline;
  margin: 0px;
  position: absolute;
  bottom: 16px;
  left: 30px;
  color: ${(props) => props.theme.space};
  background-color: ${(props) => props.theme.cream};
  padding: ${(props) => (props.padding ? '14px 0px' : '0px')};
  @media (max-width: ${breakpoints.s}px) {
    font-size: 13px;
    left: 10px;
    bottom: 10px;
  }
  @media (max-width: 380px) {
    font-size: 12px;
    left: 8px;
    bottom: 0px;
    padding: 8px 0px;
  }
  @media (max-width: 340px) {
    font-size: 11px;
  }
`

export const Label = styled(ProductLabel)`
  position: relative;
  bottom: unset;
  left: unset;
  background-color: transparent;
  text-decoration: none;
`
export const DisplayOnMobile = styled.div`
  display: none;
  @media (max-width: ${breakpoints.s}px) {
    display: block;
  }
`

export const DisplayOnDesktop = styled.div`
  display: block;
  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`
