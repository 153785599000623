import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import timeDesktop from 'src/gifs/time-desktop.gif'
import timeMobile from 'src/gifs/time-mobile.gif'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SectionWrapper } from '../LightenTheLoad/styles'
import { TimeGifImageContainer, TimeButton, StyledLink, Title } from '../styles'

import { Container, Header, TimeDisclosure, TimeContainer, BadgeContainer } from './styles'

const Time = () => {
  const { time, badge } = useStaticQuery(graphql`
    query timeQuery {
      time: file(relativePath: { eq: "time.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      badge: file(relativePath: { eq: "giftGuide/badges/selling-fast.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <SectionWrapper>
      <TimeGifImageContainer>
        <img src={timeDesktop} alt="Frame 2 Snap Frame transition" width="100%" />
        <img src={timeMobile} alt="Frame 2 Snap Frame transition" width="100%" />
      </TimeGifImageContainer>
      <Container>
        <TimeContainer>
          <GatsbyImage
            image={time.childImageSharp.gatsbyImageData}
            alt="Time Best Inventions 2024"
            loading="eager"
          />
        </TimeContainer>
        <Header>A TIME Best Invention of 2024</Header>
        <Title>Treat yourself to Calendar Max.</Title>
        <StyledLink to="/products/skylight-calendar-max">
          <TimeButton>Shop Calendar Max →</TimeButton>
        </StyledLink>
        <BadgeContainer>
          <GatsbyImage image={badge.childImageSharp.gatsbyImageData} alt="Selling Fast" />
        </BadgeContainer>
        <TimeDisclosure>©2024 TIME USA LLC. Used under license.</TimeDisclosure>
      </Container>
    </SectionWrapper>
  )
}

export default Time
