import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 8%;
  height: 100%;
  text-align: center;
  @media (max-width: 1400px) {
    left: 5%;
  }
  @media (max-width: ${breakpoints.l}px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 90%;
  }
`
export const Header = styled.h4`
  font-family: 'P22MackinacProBold';
  color: white;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.4px;
  margin: 0px;
  @media (max-width: 1400px) {
    font-size: 32px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 22px;
    letter-spacing: 0.22px;
  }
  @media (max-width: 380px) {
    font-size: 21px;
  }
  @media (max-width: 360px) {
    font-size: 18px;
  }
`
export const TimeDisclosure = styled.p`
  position: absolute;
  font-family: 'MatterRegular';
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.085px;
  bottom: 16px;
  left: 32%;
  @media (max-width: 1100px) {
    display: none;
  }
  @media (max-width: ${breakpoints.l}px) {
    display: block;
    position: absolute;
    text-align: right;
    width: 330px;
    top: 0px;
    left: 400px;
  }
  @media (max-width: 830px) {
    left: 300px;
  }
  @media (max-width: 680px) {
    left: 200px;
    position: absolute;
    text-align: right;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 130px;
    top: 0px;
    left: 340px;
  }
  @media (max-width: 500px) {
    left: 300px;
  }
  @media (max-width: 470px) {
    width: 100px;
    left: 260px;
    font-size: 8px;
  }
  @media (max-width: 400px) {
    left: 240px;
  }
  @media (max-width: 375px) {
    left: 220px;
  }
  @media (max-width: 350px) {
    left: 200px;
  }
  @media (max-width: 330px) {
    left: 180px;
  }
`
export const TimeContainer = styled.div`
  width: 125px;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 80px;
  @media (max-width: 1400px) {
    margin-top: 24px;
    width: 90px;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin-top: 60px;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 60px;
    margin-bottom: 16px;
  }
  @media (max-width: 470px) {
    margin-top: 16px;
  }
  @media (max-width: 390px) {
    width: 54px;
  }
  @media (max-width: 370px) {
    margin-bottom: 8px;
    width: 50px;
  }
  @media (max-width: 340px) {
    width: 48px;
  }
`
export const BadgeContainer = styled.div`
  width: 110px;
  position: relative;
  bottom: 60px;
  left: 500px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 90px;
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 130px;
    position: absolute;
    left: -5%;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 80px;
    left: 3%;
  }
`
