import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const CarouselContainer = styled.div`
  max-width: 1200px;
  margin: 40px auto;
  overflow: hidden;
  background-color: ${(props) => props.theme.transparent};
  padding: 0px 0px 60px;
  .slick-dots {
    bottom: -40px;
  }
  .slick-dots li button:before {
    font-size: 16px;
    color: #bdbdbd;
  }
  .slick-dots li.slick-active button:before {
    color: white;
  }
  .slick-dots li {
    margin: 0 5px;
  }
  .slick-track {
    display: flex !important;
    align-items: stretch !important;
  }
  .slick-slide {
    height: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: ${(props) => (props.mobileNoPadding ? '0px 0px 30px' : '0px 0px 48px')};
    margin: 24px auto 16px;
    .slick-dots {
      bottom: -32px;
    }
    .slick-dots li button:before {
      font-size: 12px;
    }
  }
`
export const Title = styled.h2`
  font-family: 'MatterBold';
  font-size: 27px;
  line-height: 120%;
  letter-spacing: 0.37px;
  margin: 8px 0px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 15px;
    margin: 8px 0px 24px;
  }
`
export const Header = styled.h2`
  color: ${(props) => props.theme.space};
  font-family: 'P22MackinacProBold';
  font-size: 37px;
  line-height: 120%;
  letter-spacing: 0.371px;
  margin: 0px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 32px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 24px;
    letter-spacing: 0.24px;
  }
`
export const Text = styled.p`
  color: ${(props) => props.theme.space};
  font-family: 'MatterRegular';
  font-size: 25px;
  line-height: 127%;
  letter-spacing: 0.247px;
  margin: 8px 0px 0px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 20px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 14px;
    letter-spacing: 0.13px;
  }
`
export const Description = styled.h3`
  font-family: 'P22MackinacProBold';
  font-size: 37px;
  line-height: 120%;
  letter-spacing: 0.37px;
  margin: 45px 80px 16px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 32px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 17px;
    line-height: 120%;
    letter-spacing: 0.17px;
    padding: 0px 20px;
    margin: 0px;
  }
`
export const ImageContainer = styled.div`
  width: 100%;
  div {
    width: 100% !important;
  }
`

export const Grid = styled.div`
  background-color: ${(props) => props.theme.cream};
  display: grid !important;
  grid-template-columns: ${(props) => (props.full ? '1fr' : '2fr 3fr')};
  gap: 32px;
  align-items: center;
  @media (max-width: ${breakpoints.m}px) {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 0px;
  }
`
export const TextContainer = styled.div`
  padding: 32px;
  @media (max-width: ${breakpoints.s}px) {
    padding: 24px;
  }
`
