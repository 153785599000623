import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { breakpoints } from 'src/utils/styles/global-styles'

export const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`

export const SectionWrapper = styled.div`
  display: block;
  position: relative;
  text-align: center;
  color: white;
  .content {
    position: relative;
    z-index: 2;
    padding: 20px 0px 0px;
  }
  & > .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  @media (max-width: ${breakpoints.s}px) {
    display: ${(props) => (props.desktopOnly ? 'none' : 'block')};
    /* .content {
      padding: 8px 0px;
    } */
  }
`

export const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`
export const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  text-align: center;
  &:not(:last-child) {
    border-right: 1px solid white;
  }

  padding: 20px 30px;
  img {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
  }

  h3 {
    margin: 10px 0;
    font-size: 1.2rem;
  }

  p {
    margin: 5px 0;
    font-size: 1rem;
  }

  a {
    margin-top: 10px;
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
  }
`
export const Gallery = styled.div`
  text-align: center;
  margin: auto;
  #calendar-essentials {
    margin-top: 170px;
    padding-top: 24px;
  }
  @media (max-width: ${breakpoints.m}px) {
    #calendar-essentials {
      margin-top: 50px;
      padding-top: 24px;
    }
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  max-width: 1200px;
  margin: 8px auto 0px;
  align-items: stretch;
  @media (max-width: ${breakpoints.m}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: ${(props) => (props.full ? '112%' : '100%')};
    margin-left: ${(props) => (props.full ? '-6%' : '0px')};
  }
`

export const Tile = styled.div`
  background-color: ${(props) => props.theme.cream};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.cream};
  position: relative;
  height: 100%;
  padding-bottom: 10px;
`

export const TwoSpanTile = styled(Tile)`
  grid-column: span 2;
  aspect-ratio: 2 / 1;
  aspect-ratio: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px;
  background-color: transparent;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: ${breakpoints.m}px) {
    grid-column: span 2;
  }
`
export const FullSpanTile = styled(Tile)`
  grid-column: span 3;
  aspect-ratio: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px;
  background-color: transparent;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: ${breakpoints.m}px) {
    grid-column: span 2;
  }
`

export const ProductImage = styled.div`
  padding: ${(props) => (props.padding ? '20px' : '0px')};
`
export const SectionHeader = styled.h3`
  font-family: 'MatterBold';
  color: white;
  font-size: 37px;
  line-height: 120%;
  letter-spacing: 0.37px;
  text-align: left;
  margin: 0px auto 12px;
  max-width: 1200px;
  margin-top: 56px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 20px;
    letter-spacing: 0.2px;
    margin-top: 32px;
  }
`

export const HolidayText = styled.h3`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${(props) => props.theme.space};
  text-align: center;
  font-size: 30px;
  padding: 1rem;
  line-height: 108%;
  letter-spacing: 0.3px;
  margin: 0px;
  width: ${(props) => (props.short ? '60%' : '80%')};
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    width: 80%;
  }
`
export const LightenImage = styled(GatsbyImage)`
  margin-bottom: 40px;
  @media (max-width: ${breakpoints.xl}px) {
    margin-bottom: 0px;
  }
`
export const BestSeller = styled.div`
  width: ${(props) => (props.cal ? '230px' : '120px')};
  top: ${(props) => (props.cal ? '40px' : '4px')};
  left: ${(props) => (props.cal ? '-1px' : '0%')};
  position: absolute;
  z-index: 4;
  @media (max-width: ${breakpoints.l}px) {
    width: ${(props) => (props.cal ? '130px' : '120px')};
  }
  @media (max-width: ${breakpoints.m}px) {
    top: ${(props) => (props.cal ? '16px' : '4px')};
  }
  @media (max-width: ${breakpoints.s}px) {
    width: ${(props) => (props.cal ? '86px' : '75px')};
  }
`
export const New = styled.div`
  font-family: 'MatterBold';
  line-height: 120%;
  letter-spacing: 0.2px;
  border-radius: 26px;
  background-color: #cb434c;
  padding: 4px 20px 2px;
  display: inline-block;
  margin-right: 10px;
  @media (max-width: ${breakpoints.s}px) {
    padding: 4px 12px 2px;
  }
`
export const LightenImageContainer = styled.div`
  height: 140px;
  @media (max-width: ${breakpoints.xl}px) {
    min-width: 180px;
    display: flex;
    justify-content: center;
    height: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    min-width: 100px;
    justify-content: center;
  }
`
